<template>
  <div class="loading-screen">
    <div class="progress-bar-background">
      <div class="progress-bar-fill" :style="{ width: progress + '%' }"></div>
    </div>
    <p>Loading assets, please wait...</p>
    <!-- Optional: Display loading tips or facts here -->
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 6.5%;
  left: 0;
  width: 100vw;
  height: 87%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  color: white;
  margin-top: 0;
}

.progress-bar-background {
  width: 80%;
  height: 20px;
  background-color: #eee;
  border-radius: 10px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
  transition: width 0.5s ease;
}
</style>